import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { ROUTES } from '_constants';

import { Page } from 'components/_shared/Page';
import { SocialShare } from 'components/_shared/Social';
import { BlogPostsNavigation } from '../PostsNavigation';

import { useStrapiCommonContent } from 'hooks';
import { parseMarkdown } from 'utils';

const BackLink = ({ className, ...props }) => {
  const commonContent = useStrapiCommonContent();

  const _className = cn(
    'blog-post-back-link',
    'flex max-w-2xl w-full pb-4',
    className
  );

  return (
    <div {...props} className={_className}>
      <div className='mr-2 text-base lg:text-lg rotate-180'>
        {commonContent.arrow}
      </div>

      <Link
        className='max-w-xl text-base lg:text-lg hover:underline'
        to={ROUTES.BLOG}
      >
        {commonContent.back_to_blog}
      </Link>
    </div>
  );
};

export const BlogPost = props => {
  const { pageContext: post } = props;

  const authors = post.authors.reduce((authors, author, i, arr) => {
    const isLast = !arr[i + 1];
    if (i === 0) return `${author.name}`;
    return `${authors}${isLast ? ' and' : ','} ${author.name}`;
  }, '');

  return (
    <Page
      className='page-news-post'
      meta={{
        title: post.seo?.title || post.title,
        description: post.seo?.description || post.excerpt?.data?.excerpt,
        keywords: post.seo?.keywords,
        image: post.image?.url
      }}
    >
      <div className='header-spacer h-24' />

      <article>
        <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
          <div className='flex md:hidden w-full flex-shrink-0'>
            <BackLink />
          </div>

          <div>
            <header className='w-full md:hidden flex flex-col relative'>
              <h1 className='text-2xl leading-tight'>{post.title}</h1>

              {authors && (
                <p className='authors mt-4 text-lg lg:text-xl'>by {authors}</p>
              )}

              <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                {post.date && (
                  <time
                    className='text-lg md:text-lg lg:text-xl leading-tight text-gray-400 mr-4 mb-6'
                    dateTime={post.date}
                  >
                    {post.date}
                  </time>
                )}

                <SocialShare
                  className='mb-4'
                  title={post.title}
                  description={post.excerpt?.data?.excerpt}
                />
              </div>
            </header>

            {post.image?.localFile && (
              <div className='w-full'>
                <div className='w-full bg-cover bg-center mb-8 lg:mb-12'>
                  <GatsbyImage
                    className='w-full'
                    image={getImage(post.image.localFile)}
                    alt={post.title}
                  />
                </div>
              </div>
            )}

            <div className='flex mt-6 flex-col md:flex-row'>
              <div className='md:w-1/3 w-full md:mr-6'>
                <header className='w-full hidden md:flex flex-col relative'>
                  <BackLink />

                  <h1 className='text-lg md:text-2xl lg:text-4xl leading-tight'>
                    {post.title}
                  </h1>

                  {authors && (
                    <p className='authors mt-4 text-lg lg:text-xl'>
                      by {authors}
                    </p>
                  )}

                  <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                    {post.date && (
                      <time
                        className='text-lg md:text-lg lg:text-xl leading-tight text-gray-400 mr-4 mb-6'
                        dateTime={post.date}
                      >
                        {post.date}
                      </time>
                    )}

                    <SocialShare
                      className='mb-4'
                      title={post.title}
                      description={post.excerpt}
                    />
                  </div>
                </header>
              </div>
              <main className='w-full'>
                <div className='text-lg md:text-xl lg:text-2xl leading-tight'>
                  {parseMarkdown(post.content?.data?.content, {
                    wrapper: { class: 'default' }
                  })}
                </div>

                <hr className='my-12' />

                <BlogPostsNavigation post={post} />
              </main>
            </div>
          </div>
        </div>
      </article>
    </Page>
  );
};
