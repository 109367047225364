import React from 'react';

import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';
import { PostList } from 'components/Post';

import { useStrapiBlogPosts } from 'hooks';

export const BlogPage = props => {
  const { page } = props.data;
  const { sortedPosts } = useStrapiBlogPosts();

  return (
    <Page
      className='page-blog'
      meta={{
        title: page.seotitle,
        description: page.seodescription,
        image: page.preview_img?.url
      }}
      withContainer
    >
      <Section
        heading={page.pageheading}
        subheading={page.pagesubheading}
        variant='aside'
      >
        <PostList posts={sortedPosts} variant='primary' />
      </Section>
    </Page>
  );
};
