import React from 'react';
import cn from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { LogoIconColor } from 'assets';

import { Markdown } from 'components/_shared/Markdown';
import { Link } from 'components/_shared/Link';

import { useStrapiCommonContent } from 'hooks';
import { truncateText } from 'utils';

import './PostList.styles.scss';

export const PostList = ({
  className,
  posts = [],
  variant,
  excerptLength = 188
}) => {
  const commonContent = useStrapiCommonContent();
  const _className = cn('post-list', className, variant);
  if (!posts?.length) return null;

  const truncateExcerpt = (excerpt, postIndex) => {
    if (variant === 'primary' && postIndex === 0) {
      return truncateText(excerpt, excerptLength * 2);
    }

    return truncateText(excerpt, excerptLength);
  };

  return (
    <ul className={_className}>
      {posts.map((post, i) => (
        <li key={post.id || i} className='post-list-item'>
          <article className='post'>
            {post.image?.localFile ? (
              <div className='post-thumbnail image'>
                <GatsbyImage
                  image={getImage(post.image.localFile)}
                  alt={post.title}
                />
              </div>
            ) : (
              <div className='post-thumbnail default'>
                <LogoIconColor />
              </div>
            )}

            <div className='post-content'>
              <h4 className='post-title'>{post.title}</h4>

              {post.date && (
                <time className='post-date' dateTime={post.date}>
                  {post.date}
                </time>
              )}

              {post.excerpt && (
                <Markdown
                  className='post-excerpt'
                  markdown={truncateExcerpt(post.excerpt?.data?.excerpt, i)}
                />
              )}

              <Link className='post-link continue' href={post.url}>
                {`${commonContent.read_more} ${commonContent.arrow}`}
              </Link>
            </div>

            <Link className='post-link overlay' href={post.url} />
          </article>
        </li>
      ))}
    </ul>
  );
};
