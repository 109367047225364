import React, { useMemo } from 'react';
import cn from 'classnames';

import { PostsNavigation } from 'components/_shared/PostsNavigation';

import { useStrapiBlogPosts } from 'hooks';

export const BlogPostsNavigation = ({ className, post = {}, ...props }) => {
  const _className = cn('blog-posts-navigation', className);

  const { sortedPosts } = useStrapiBlogPosts();

  const [prevPost, nextPost] = useMemo(() => {
    const postIndex = sortedPosts.findIndex(({ slug }) => slug === post.slug);
    return [sortedPosts[postIndex - 1], sortedPosts[postIndex + 1]];
  }, [post.slug, sortedPosts]);
  if (!prevPost && !nextPost) return null;

  return (
    <PostsNavigation
      {...props}
      className={_className}
      prev={prevPost}
      next={nextPost}
    />
  );
};
