import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import upperFirst from 'lodash-es/upperFirst';

import './PostsNavigation.styles.scss';

export const PostsNavigation = ({ className, prev, next, ...props }) => {
  const _className = cn('posts-navigation', className);

  const renderPost = ([key, post]) => {
    if (!post || !post?.url) return null;

    const _className = cn('posts-navigation-item', key);

    return (
      <Link key={post.url} className={_className} to={post.url}>
        {post.image && (
          <div className='post-image'>
            <GatsbyImage
              image={getImage(post.image.localFile)}
              alt={post.title}
            />
          </div>
        )}

        {(post.title || post.excerpt) && (
          <div className='post-content'>
            <div className='post-direction'>{upperFirst(key)}</div>
            {post.title && <h4 className='post-title'>{post.title}</h4>}
          </div>
        )}
      </Link>
    );
  };

  return (
    <div {...props} className={_className}>
      {Object.entries({ prev, next }).map(renderPost)}
    </div>
  );
};
