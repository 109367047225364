import React from 'react';
import { graphql } from 'gatsby';

import { BlogPage } from 'components/Blog';

export default function PageBlog(props) {
  return <BlogPage {...props} />;
}

export const query = graphql`
  query PageBlogQuery {
    page: strapiBlogpage {
      pageheading
      pagesubheading
      seotitle
      seodescription
      preview_img {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
          }
        }
      }
    }
  }
`;
